<template>
  <div>后台信箱</div>
</template>

<script>
export default {
  name: 'Email',
}
</script>

<style scoped>

</style>
